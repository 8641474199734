<template>
    <auth-layout>
        <div class="ce-login-card" v-show="showResetPanel">
            <h3 class="ce-login-card-title">Enter New Password</h3>
            <div class="ce-login-card-item-wrap">
                <input type="text" name="resetType" value="username" hidden="">

                <div class="ce-login-card-item-wrap-content">
                    <div class="form-group ce-login-card-item-wrap-input ce-byemail"
                         id="reset_type_email">
                        <input id="password" type="password" v-model="data.password" class="form-control"
                               placeholder="Enter new password..." @change="clear">
                    </div>

                    <div class="form-group reset_type ce-login-card-item-wrap-input ce-byphone"
                         id="reset_type_phone">
                        <input id="confirm-password" type="password" v-model="data.password_confirmation"
                               class="form-control"
                               placeholder="Confirm password..." @change="clear">
                    </div>
                </div>

              <label class="nothing-to-display" v-if="hasError">
                {{ errorMessage }}
              </label>

                <div class="ce-login-btn-wrap  ce-reset-username-wrap">
                    <button type="submit" v-on:click="submit" class="btn ce-btn-verification-submit">
                        Submit
                    </button>&nbsp;

                    <a href="/" class="btn ce-btn-cancel-submit">
                        Cancel
                    </a>
                </div>

            </div>
        </div>
        <div class="ce-login-card" v-show="showResetPanel===false">
          {{verificationResponseMessage}}
          <div class="ce-login-btn-wrap  ce-reset-username-wrap">
          <a href="/reset-password" class="btn ce-btn-cancel-submit">
            Resend
          </a>&nbsp;
          </div>
        </div>
    </auth-layout>
</template>
<script>
    import AuthLayout from '../layouts/AuthLayout';
    import Api from '../includes/Api';
    import {MUTATION_LOGOUT} from "../store/modules/User";

    export default {
        data() {
            return {
                data: {
                    password_confirmation: '',
                    password: '',
                    user_id: '',
                    token: ''
                },
                hasError: false,
                errorMessage:null,
                showResetPanel: null,
                verificationResponseMessage:null

            }
        },
        components: {
            'auth-layout': AuthLayout
        },
        methods: {
            submit() {
                if (this.data.password_confirmation !== this.data.password) {
                    this.hasError = true;
                    this.errorMessage = "Passwords do not match!!!"
                } else {
                    Api.post('/reset-password-code', this.data).then((response) => {
                      if( response.data.status === 'success' ) {
                        Api.alert('success',response.data.message);
                      }
                        this.$store.commit('User/'+MUTATION_LOGOUT);
                        this.$router.push('/');
                    });
                }
            },
            checkTokenExpiry(){
              Api.post('/verify-forgot-password-code-expiry', this.data).then((response) => {

                if( response.data.status_code === 204 ) {
                  this.verificationResponseMessage = response.data.message;
                  this.showResetPanel = false;
                  Api.alert('error',response.data.message);
                } else {
                  this.verificationResponseMessage = null;
                  this.showResetPanel = true;
                }

              });
            },
          clear(){
                this.hasError = false;
                this.errorMessage = null;
          },

        },
        created() {
            this.data.resetCode = this.$route.params.token;
        },
      mounted(){
          this.checkTokenExpiry();
      }
    }
</script>

